<template>
  <div class="setting">
    <Nav navType="fun_text" textCon="注销账户">
      <template v-slot:rightText>
        <span class="iconfont icongengduo" style="color:#000;font-size:24px"></span>
      </template>
    </Nav>
    <div class="title">
      请选择注销原因<span>(最多可选3个)</span>
    </div>
    <div class="items" v-for="(item, index) in selection" :key="index">
      <div class="label">{{item}}</div>
      <div class="check_button">
        <checkbox v-model="checked[index]" checked-color="#ff8000"></checkbox>
      </div>
    </div>
    <div class="button" @click="submit">确认</div>
    <!-- 弹窗 -->
    <Popup class="explain" v-model="show_explain" :close-on-click-overlay="false">
      <div class="ex_title">注销账户后会：</div>
      <div class="info">
        1.您将不再享受“乐享靖江”及“乐享靖江”平台旗下相关产品及服务（
        <span>包括但不限于商家端等其它客户端</span>
        ）的专属会员权益；
      </div>
      <div class="info">
        2.您将再也
        <span>无法进行登陆</span>
        （包括但不限于商家端等其它客户端）、
        <span>忘记密码</span>
        等操作；
      </div>
      <div class="info">
        3.您账户的
        <span>个人资料及历史纪录</span>
        将无法找回；
      </div>
      <div class="info">
        4.您账户中如存在积分（
        <span>包括但不限于余额、分享金、乐通卡等</span>
        ）未使用，注销后将不能再使用；
      </div>
      <div class="info">
        5.您账户中如果存在优惠券（
        <span>包括但不限于商家券、平台券等</span>
        ）未使用，注销后将不能再使用；
      </div>
      <div class="info">
        6.除法律法规要求必须保存的信息以外，您的用户信息及记录，我们将予以删除或使其保持不可被检索、访问或对其进行匿名化处理，同时您账户下所有行为信息记录，您也将无法找回。
      </div>
      <div class="info">
        7.注销本账户并不代表本账户注销前的账户行为和相关责任得到豁免或减轻。
      </div>
      <div class="know" @click="show_explain = false">我知道了</div>
    </Popup>
    <Popup v-model="show_success" class="success">
      <div class="top"></div>
      <div class="success_text">注销成功</div>
      <div class="success_info">您的账户已成功注销，</div>
      <div class="success_info">如有需要，请返回首页重新注册新账户</div>
      <div class="success_button">返回首页</div>
    </Popup>
  </div>
</template>
<script>
import Nav from "@components/public/searchTop";
import { Popup,Checkbox } from "vant";
export default {
  data() {
    return {
      cacheNum: 38.63,
      show_explain:true,
      selection:[],
      checked:[],
      show_success:false,
    };
  },
  mounted(){
    this.init()
  },
  methods:{
    init(){
      this.selection = [
        "需要解换绑手机",
        "该注册手机账户已弃用",
        "存在两个及以上该软件账号",
        "平台频繁促销提醒或短信骚扰",
        "安全/隐私顾虑",
        "软件体验感不佳",
        "其他"
      ]
    },
    submit(){
      this.show_success = true;
    }
  },
  components: {
    Nav,
    Popup,
    Checkbox
  }
};
</script>
<style lang="scss" scoped>
.setting {
  width: 100%;
  height: auto;
  padding:0 12px;
  padding-top: 45px;
  background-color: #f4f4f4;
  font-family:PingFang SC;
  font-weight:500;
  padding-bottom: 1px;
  min-height: 100vh;
  .title{
    padding-top: 29px;
    color:#333;
    font-size: 15px;
    margin-bottom: 24px;
    span{
      font-size: 12px;
    }
  }
  .explain{
    width:76%;
    border-radius: 5px;
    padding-left: 9px;
    padding-right: 8px;
    color:#333;
    .ex_title{
      height: 43px;
      line-height: 43px;
      font-size: 15px;
      text-align: center;
    }
    .info{
      font-size: 13px;
      line-height: 17px;
      span{
        color:#FF8000
      }
    }
    .know{
      margin:10px auto;
      width:138px;
      height: 30px;
      line-height: 30px;
      border-radius: 15px;
      text-align: center;
      font-size: 13px;
      color:#fff;
      background:linear-gradient(-90deg,rgba(255,128,0,1) 0%,rgba(255,177,1,1) 100%);
    }
  }
  .items{
    height: 45px;
    font-size: 14px;
    line-height: 45px;
    color:#333;
    margin-bottom: 12px;
    border-radius: 5px;
    background-color: #fff;
    padding: 0 10px;
    display: flex;
    .label{
      flex:1;
    }
    .check_button{
      width:20px;
      text-align: right;
      padding-top: 11px;
    }
  }
  .button{
    height: 45px;
    line-height: 45px;
    margin-top: 30px;
    font-size: 15px;
    color:#fff;
    text-align: center;
    border-radius: 5px;
    background:linear-gradient(-90deg,rgba(255,128,0,1) 0%,rgba(255,177,1,1) 100%);
  }
  .success{
    width:66%;
    padding:0 13px;
    text-align: center;
    .top{
      margin: 0 auto;
      margin-top: 35px;
      width:84px;
      height: 84px;
      border-radius:42px;
      background:linear-gradient(-90deg,rgba(255,128,0,1) 0%,rgba(255,177,1,1) 100%);
    }
    .success_text{
      margin-top: 20px;
      margin-bottom: 15px;
      font-size: 16px;
    }
    .success_info{
      font-size: 13px;
      line-height: 17px;
    }
    .success_button{
      margin:14px auto 24px auto;
      width:138px;
      height: 30px;
      line-height: 30px;
      border-radius: 15px;
      font-size: 13px;
      color:#fff;
      background:linear-gradient(-90deg,rgba(255,128,0,1) 0%,rgba(255,177,1,1) 100%);
    }
  }
}
</style>
